<template>
  <div>
    <!-- Breadcrumbs display -->

    <span
      v-for="(list, index) in breadCrumbsData"
      :key="index"
      :class="[
        'openLinkChart',
        { 'open-link': index !== breadCrumbsData.length - 1 }
      ]"
    >
      <!-- - 1 -->
      <button
        v-if="breadCrumbsData.length"
        :disabled="index === breadCrumbsData.length - 1"
        @click="resetChart(list)"
      >
        {{ list.date }}
        <span v-if="index < breadCrumbsData.length - 1"> <span><v-icon> mdi-chevron-right </v-icon></span> </span>
      </button>
    </span>

    <!-- Chart component -->
    <apexchart
      ref="apexchart"
      type="bar"
      :options="chartOptions"
      :series="series"
      :height="height"
      :width="width"
      :class="{ 'clickable': drillDownEnabled, 'no-click': !drillDownEnabled }"
      @click="selectedBar"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { mapGetters } from "vuex";
import moment from 'moment';

export default {
  name: 'BarChart',
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    height: {
      type: [String, Number],
      default: 400,
    },
    width: {
      type: [String, Number],
      default: 500,
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    cursorDrill: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    let initialBreadcrumb = [{
      date: 'Reset',
      start: this.options.date ? this.options.date[0] : null,
      end: this.options.date ? this.options.date[1] : null
    }];
    return {
      objOptions: {},
      initial: [...initialBreadcrumb],
      originalSeries: [],
      maxTripCount: 0,
      series: [
        {
          name: 'Trip Count',
          data: [],
        },
      ],
      chartOptions: {
        ...this.options,
        chart: {
          type: 'bar',
          width: '100%',
          height: '100%',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '30%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            //text: 'Trip Count',
          },
          min: 0,
          max: this.maxTripCount,
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          x: {
            formatter: (val, opts) => {
              // Access start and end date from data
              const startDate = this.data[opts.dataPointIndex].period_start_date;
              const endDate = this.data[opts.dataPointIndex].period_end_date;

              // Format dates with time using moment.js (if imported)
              const startFormatted = moment(startDate).format("MM/DD HH:mm");
              const endFormatted = moment(endDate).format("MM/DD HH:mm");

              return `${startFormatted} - ${endFormatted}`;
            },
          },
          y: {
            formatter(val) {
              return + val + ' trips';
            },
          },
        },
      },
      drillDown: false,
      selectedDay: null,
      loading: false,
      drillDownEnabled: true,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      breadCrumbsData: "dashboard/getTripBreadCrumb",
    }),
  },
  watch: {
    data: {
      handler() {
        this.updateChartData();
      },
      immediate: true,
      deep: true,
    },
    cursorDrill: {
      handler(newVal) {
        if (newVal) {
          this.drillDownEnabled = newVal;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.updateChartData();
  },
  methods: {
    xAxisValues(start, end) {
      const startDate = new Date(start)
      const endDate = new Date(end)
      const timeDifference = Math.abs(endDate - startDate);
      const hourDifference = timeDifference / (1000 * 60 * 60);
      let dateformat = "MM/DD H:mm";
      if (startDate.getFullYear() !== endDate.getFullYear()) {
        dateformat = "YYYY/MMM/DD";
      } else if (startDate.getDay() == endDate.getDay()) {
        dateformat = "MM/DD H:mm"
      } else if (startDate.getDay() != endDate.getDay() && hourDifference <= 3) {
        dateformat = "MM/DD H:mm"
      }
      const initDate = moment(start).format(dateformat)
      return `${initDate}`;
    },
    setDrillDownValue(start, end) {
      const startDate = new Date(start)
      const endDate = new Date(end)
      const timeDifference = Math.abs(endDate - startDate);
      const hourDifference = timeDifference / (1000 * 60 * 60);
      if (hourDifference > 3) {
        return true;
      } else {
        return false;
      }
    },
    updateChartData() {
      if (this.data && this.data.length > 0) {
        this.series[0].data = this.data.map(item => item.tripCount);
        this.chartOptions.xaxis.categories = this.data.map(item => {
          return this.xAxisValues(item.period_start_date, item.period_end_date)
        });
        this.$nextTick(() => {
          if (this.$refs.apexchart && this.$refs.apexchart.updateOptions) {
            this.$refs.apexchart.updateOptions(this.chartOptions);
          }
        });
        this.data.forEach(item => {
          if (item.tripCount > this.maxTripCount) {
            this.maxTripCount = item.tripCount;
          }
        });
      }
    },
    selectedBar(event, chartContext, opts) {
      if (this.loading || !this.drillDownEnabled) return;

      this.loading = true;  // Prevent further clicks

      const selectedBarIndex = opts.dataPointIndex;
      if (this.data && selectedBarIndex >= 0 && selectedBarIndex < this.data.length) {
        const clickedBarData = this.data[selectedBarIndex];
        const startDate = clickedBarData.period_start_date;
        const endDate = clickedBarData.period_end_date;
        this.objOptions = { ...this.options }
        this.objOptions.date = [
          moment(clickedBarData.period_start_date).format("YYYY-MM-DD HH:mm:ss"),
          moment(clickedBarData.period_end_date).format("YYYY-MM-DD HH:mm:ss")
        ];
        if (startDate && endDate) {
          this.objOptions.dateType = 'custom';
        } else {
          delete this.objOptions.dateType;
        }
        this.drillDownEnabled = this.setDrillDownValue(clickedBarData.period_start_date, clickedBarData.period_end_date);

        if (this.drillDownEnabled) {
          let value = this.xAxisValues(clickedBarData.period_start_date, clickedBarData.period_end_date);
          // Dispatch API call and update breadcrumb data
          this.$store.dispatch("dashboard/vechicleTrips", this.objOptions)
            .then(() => {
              this.$emit("resetButton");
              let breadcrumbData = this.$store.getters['dashboard/getTripBreadCrumb'];
              breadcrumbData.push({
                date: value,
                start: startDate,
                end: endDate,
              });
              this.$store.dispatch("dashboard/setTripBreadCrumb", breadcrumbData);
            })
            .finally(() => {
              this.loading = false;  // Reset loading state
            });
        } else {
          this.loading = false;  // Reset loading state if drill-down is disabled
          this.$emit("resetCursorValue", false);

        }
      } else {
        this.loading = false;  // Reset loading state if index is out of bounds
      }
    }
    ,
    resetChart(list) {
      let breadcrumbData = this.$store.getters['dashboard/getTripBreadCrumb'];
      const index = breadcrumbData.findIndex(breadcrumb => breadcrumb.date === list.date);
      if (index !== -1) {
        breadcrumbData.splice(index + 1);
        this.objOptions = { ...this.options }
        this.objOptions.date = [list.start, list.end];
        if (list.start == null && list.end == null) {
          delete this.objOptions.dateType;
        }
        this.$store.dispatch("dashboard/vechicleTrips", this.objOptions);
        this.drillDownEnabled = true;
        this.$store.dispatch("dashboard/setTripBreadCrumb", breadcrumbData);
      }
    },

  },
};
</script>


<style scoped>
.clickable {
  cursor: pointer;
}

.no-click {
  cursor: not-allowed;
}
</style>